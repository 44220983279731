import React from 'react'
import Layout from '../components/layout'

import notFoundStyles from "./404.module.scss"

const NotFound = () => {
    return(
        <Layout>
            <div className={notFoundStyles.notFoundMain}>
                <h1 className={notFoundStyles.warningText}>Page doesn't exist.</h1>
                <p className={notFoundStyles.additionalText}>You stumbled upon a page that doesn't exist (or is under construction).</p>
                <p>Please navigate to a working page of the website using the menu above.</p>
            </div>
        </Layout>
    )
}

export default NotFound